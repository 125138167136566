<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Uusi omistaja</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <partner-form submit-btn-text="Luo" @sendpartner="submit($event)"></partner-form>
  </div>
</template>

<script>
import PartnerForm from "../../components/Partner/PartnerForm";
import mixins from "../../mixins/mixins";
import { mapActions } from "vuex";

export default {
  title: "Uusi omistaja",

  components: {
    PartnerForm,
  },

  mixins: [mixins],

  methods: {
    ...mapActions("partner", ["createPartner"]),

    async submit(partner) {
      try {
        await this.createPartner(partner);
        this.showPopup("Uusi omistaja luotu", "success");
        this.$router.push("/partners");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
