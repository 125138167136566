<template>
  <div>
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan toimeksiantajaa..."
      class="full-page-loader"
    ></full-page-loader>

    <!-- FORM -->
    <v-form v-if="!loading" ref="form">
      <v-card class="mt-2">
        <v-card-title
          ><div>Perustiedot</div>
          <v-spacer></v-spacer>
          <router-link :to="`/partner/overview/${$route.params.id}`">
            <v-btn color="primary">Yleisnäkymään</v-btn>
          </router-link>
        </v-card-title>

        <v-container>
          <v-radio-group
            v-model="partner.isCompany"
            :mandatory="true"
            @change="partner.isCompany ? (partner.social = '') : (partner.businessId = '')"
          >
            <v-radio label="Yksityinen" :value="false"></v-radio>
            <v-radio label="Yritys" :value="true"></v-radio>
          </v-radio-group>

          <h4>Toimeksiantaja</h4>

          <v-row dense v-if="partner.isCompany">
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-checkbox
                v-model="fetchFromYtj"
                label="Salli YTJ:n hakea tietoja"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="partner.name"
                :rules="validations.required"
                label="Nimi"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-if="partner.isCompany"
                v-model.trim="partner.businessId"
                label="Y-tunnus"
                outlined
                dense
                @input="checkYTJ"
                hide-details
              ></v-text-field>

              <v-text-field
                v-if="!partner.isCompany"
                v-model.trim="partner.social"
                label="Henkilötunnus"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="partner.phone"
                label="Puhelin"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="partner.email"
                label="Email"
                :rules="validations.email2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Osoite</h4>
          <!-- 
                <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                v-model="nominatimAddress"
                :items="foundAddresses"
                :item-text="nominatimHelpers.getAddressListName"
                outlined
                label="Hae osoitteella"
                dense
                return-object
                no-filter
                @change="
                  setAddress($event);
                  clearInput();
                "
                @input.native="getAddresses"
                hide-details
              ></v-autocomplete> </v-col
          ></v-row> -->

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="partner.address"
                label="Osoite"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="partner.zipCode"
                label="Postinumero"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="partner.city"
                label="Kaupunki"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Lisätiedot</h4>

          <v-row dense>
            <v-col md="6">
              <v-textarea
                v-model="partner.comments"
                placeholder="Muuta tietoa..."
                outlined
                dense
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Laskutustiedot</v-card-title>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="partner.bank"
                label="Pankki"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="partner.iban"
                label="Tilinumero"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="info" class="mr-4 mt-5" @click="submit">{{ submitBtnText }}</v-btn>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import axios from "axios";
import FullPageLoader from "@/components/FullPageLoader";
import { mapState, mapMutations } from "vuex";
import validations from "@/validations";

export default {
  mixins: [mixins],

  components: {
    FullPageLoader,
  },

  props: {
    part: {
      type: Object,
      default: function () {
        return {};
      },
    },
    submitBtnText: { type: String, default: "" },
  },

  data() {
    return {
      fetchFromYtj: false,
      partner: {
        isCompany: false,
        name: "",
        businessId: "",
        social: "",
        email: "",
        address: "",
        zipCode: "",
        city: "",
        phone: "",
        bank: "",
        iban: "",
        comments: "",
      },
      validations,
    };
  },

  watch: {
    part: function (val) {
      this.partner = val || {};
      this.setLoading(false);
    },
    "partner.social": function () {
      if (this.partner.social) this.partner.social = this.partner.social.toUpperCase();
      this.partner.businessId = "";
    },
    "partner.businessId": function () {
      this.partner.social = "";
    },
    fetchFromYtj(val) {
      if (val) this.checkYTJ();
    },
  },

  computed: {
    ...mapState("partner", ["loading"]),
  },

  methods: {
    ...mapMutations("partner", ["setLoading"]),

    async submit() {
      if (this.$refs.form.validate()) {
        this.$emit("sendpartner", this.partner);
      }
    },

    async checkYTJ() {
      if (this.fetchFromYtj) {
        if (
          this.partner.businessId &&
          this.partner.businessId.length >= 9 &&
          this.partner.businessId.includes("-")
        ) {
          try {
            let res = await axios.get(`https://avoindata.prh.fi/bis/v1/${this.partner.businessId}`);
            let data = res.data.results[0];

            this.partner.name = data.name;

            let administrator = data.addresses.filter((el) => el.version == 1 && el.street != null);
            if (administrator[0]) {
              this.partner.address = administrator[0].street;
              this.partner.zipCode = administrator[0].postCode;
              let city = String(administrator[0].city).toLowerCase();
              this.partner.city = city.charAt(0).toUpperCase() + city.slice(1);
            }
            let administratorPhone = data.contactDetails.filter(
              (el) => el.version == 1 && el.type == "Matkapuhelin"
            );
            if (administratorPhone[0]) this.partner.phone = administratorPhone[0].value;
          } catch (err) {
            this.showPopup(
              "Kaikkia kenttiä tai osaa kenttien tiedoista ei voitu täyttää.",
              "error"
            );
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
